@import colours

.card-container
    min-height: 100%

    .card
        background: $white
        height: 100%
        min-height: max-content
        border: none
        border-radius: $boxRadius
        transition: all 0.2s
        box-shadow: $boxShadow

        hr
            margin: 1.5rem 0

        .card-title
            margin: 0.5rem 0
            font-size: 0.9rem
            letter-spacing: $letterSpacing
            font-weight: bold

        .card-price
            font-size: 3rem

        ul li
            list-style-type: none
            margin: 0.8rem 0
            padding: 0

            .icon
                margin-right: 5px

        .btn
            font-size: 1rem
            border-radius: 4rem
            letter-spacing: $letterSpacing
            padding: 1rem
            opacity: 0.7
            transition: all 0.2s

        .card:hover .btn
            opacity: 1
