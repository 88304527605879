@import colours
html
    padding: 0
    margin: 0
    background-color: $white
body
    min-height: 100vh
    padding: 0
    margin: 0
    background-color: $white

    a
        text-decoration: none!important

    .secondary-button
        color: $white
        background-color: $green

        &:hover
            color: $white
            background-color: $hoverGreen

    .custom-navbar
        min-height: 5rem
        max-height: 5rem
        padding: 1rem 9vw
        .navbar
            .nav-link
                color: #00000080
                position: relative
                font-weight: 500
                &:hover
                    cursor: pointer

            & :after
                content: ""
                position: absolute
                bottom: 0
                left: 50%
                transform: translateX(-50%)
                width: 2rem
                height: 3px
                background-color: $navbarBlue
                opacity: 0
                transition: all .3s cubic-bezier(.165,.84,.44,1)

        .nav-link.active:after
            opacity: 1

    @media ( max-width: 768px)
        .custom-navbar
            padding: 1rem 1rem

    main
        height: max-content
        margin: 0 10vw

        .home
            height: max-content
            .home-container
                min-height: 70vh
                height: max-content
                margin-bottom: 10vh

                .home-content
                    .home-header
                        font-size: 3rem
                        img
                            width: 50%
                        padding-bottom: 2rem

            .icd-results-container
                min-height: 90vh

        .pricing
            height: 85%
            margin: 3rem 0

        .developer .developer-container
            .developer-content
                margin: 3rem 0

            .developer-content-header
                letter-spacing: $letterSpacing

    .capsule
        height: 26px
        border-radius: 13px